import React from 'react';

import { OuterLink } from '@shared/ui/GenericLink';

const DocumentationPage = () => {
  return (
    <div className="container_xl pt-6 pb-12 lg:pt-12 xl:pb-52">
      <h1 className="text-3xl mb-4">Нормативні документи</h1>
      <ul className="pl-5 list-disc space-y-4">
        <li>
          <OuterLink className="text-shamrock hover:underline" to="https://zakon.rada.gov.ua/laws/show/1192-14#Text">
            Закон України Про гуманітарну допомогу
          </OuterLink>
        </li>
        <li>
          <OuterLink className="text-shamrock hover:underline" to="https://zakon.rada.gov.ua/laws/show/5073-17#Text">
            Закон України Про благодійну діяльність та благодійні організації
          </OuterLink>
        </li>
        <li>
          <OuterLink className="text-shamrock hover:underline" to="https://www.president.gov.ua/documents/932022-41489">
            Указ президента України №93/2022 Про координацію заходів з вирішення гуманітарних та соціальних питань
          </OuterLink>
        </li>
        <li>
          <OuterLink className="text-shamrock hover:underline" to="https://www.president.gov.ua/documents/642022-41397">
            Указ президента України №64/2022 Про введення воєнного стану в Україні
          </OuterLink>
        </li>
        <li>
          <OuterLink
            className="text-shamrock hover:underline"
            to="https://www.kmu.gov.ua/npas/deyaki-pitannya-viznannya-tovariv-gumanitarnoyu-dopomogoyu-ta-yih-vikoristannya-v-umovah-voyennogo-stanu-238"
          >
            Постанова Кабінету Міністрів від 09 березня 2022 р. № 238 Деякі питання визнання товарів гуманітарною
            допомогою та їх використання в умовах воєнного стану
          </OuterLink>
        </li>
        <li>
          <OuterLink
            className="text-shamrock hover:underline"
            to="https://www.kmu.gov.ua/npas/pro-osoblivosti-roboti-akcionernogo-tovaristva-ukrposhta-v-umovah-voyennogo-stanu-305"
          >
            Постанова Кабінету Міністрів від 17 березня 2022 р. № 305 Про особливості роботи акціонерного товариства
            “Укрпошта” в умовах воєнного стану
          </OuterLink>
        </li>
      </ul>
    </div>
  );
};

export default DocumentationPage;
